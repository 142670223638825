<template>
  <div style="width: 100%;height: 100vh;background-color: rgb(8, 20, 32)">
    <div style="position: absolute;left: 25%;top: 2%">
      <img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/show_progess.png" style="width: 80%">
    </div>

    <!--    头部-->
    <div style="height: 100px;display: flex;justify-content: space-between">
      <!--      日期选择-->
      <div style="padding-top: 25px;padding-left: 50px">
        <el-date-picker
            v-model="time"
            type="date"
            placeholder="选择日期"
            format="YYYY-MM-DD"
            value-format="YYYY-MM-DD"
            size="mini"
            @change="updateTimeRe"
            style="color: #fff"
        >
        </el-date-picker>

      </div>

      <!--      时间-->
      <div id="timestyle">
        {{ timenow }}
      </div>

    </div>
    <!--    开累-->
    <div style="display: flex;height: 10px;justify-content: space-between">
      <div style="display: flex;justify-content: space-between;width: 20%;height: 30px;line-height: 30px;font-size: 15px;padding-left: 10px;margin-left: 50px;margin-top: 15px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
        <span>正洞开挖进度 </span>
        <span style="margin-left: -20%">{{ sumMile }} 米</span>
        <a href="https://www.cr12cz.cn/#/show_alone?bdst=1&tunnel=0" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
      </div>

      <div style="display: flex;justify-content: space-between;width: 20%;height: 30px;line-height: 30px;font-size: 15px;padding-left: 10px;margin-right: 65px;margin-top: 15px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
        <span>各隧道日进度 </span>
        <span style="margin-left: -20%">{{ daydennum }} 米</span>
        <a :href="dayendurl" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
      </div>

      <div style="display: flex;justify-content: space-between;width: 20%;height: 30px;line-height: 30px;font-size: 15px;padding-left: 10px;margin-right: 25px;margin-top: 15px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
        <span>各隧道日进度 </span>
        <span style="margin-left: -20%">{{ daydennum }} 米</span>
        <a :href="dayendurl" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
      </div>

      <div style="display: flex;justify-content: space-between;width: 20%;height: 30px;line-height: 30px;font-size: 15px;padding-left: 10px;margin-right: 50px;margin-top: 15px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
        <span>辅助坑道开挖进度 </span>
        <span style="margin-left: -20%">{{ sumAllZ }} 米</span>
        <a href="https://www.cr12cz.cn/#/show_alone?bdst=1&tunnel=1" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
      </div>
    </div>

    <div style="display: flex;height: 25%;justify-content: space-between">

      <div id="myEchartsZ" style="width: 20%;height: 90%;margin-left: 50px;margin-top: 20px;z-index: 3"></div>
      <div id="myEchartsOperDayNum"
           style="width: 23%;height: 90%;margin-right: 20px;margin-top: 23px;z-index: 3"></div>
      <div id="myEchartsOperDayNuma"
           style="width: 20%;height: 90%;margin-right: 50px;margin-top: 23px;z-index: 3"></div>
      <div id="myEchartsF" style="width: 20%;height: 90%;margin-right: 50px;margin-top: 20px;z-index: 3"></div>
    </div>
    <!--    超欠挖、初支混凝土用量-->
    <div style="display: flex;height: 10px;justify-content: space-between">
      <div style="display: flex;justify-content: space-between;width: 20%;height: 30px;line-height: 30px;font-size: 15px;padding-left: 10px;margin-left: 50px;margin-top: 15px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
        <span>初支混凝土用量 </span>
        <span style="margin-left: -20%"></span>
        <a :href="czurl" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
      </div>

      <div style="display: flex;justify-content: space-between;width: 20%;height: 30px;line-height: 30px;font-size: 15px;padding-left: 10px;margin-right: 50px;margin-top: 18px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
        <span>超欠挖 </span>
        <span style="margin-left: -20%"></span>
        <a :href="chaowaurl" target="_self"><img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click=""></a>
      </div>

    </div>
    <div style="display: flex;height: 25%;justify-content: space-between;margin-top: 20px">
      <div id="myEchartsHunNum" style="width: 20%;height: 100%;margin-left: 50px;margin-top: 15px"></div>
      <div id="myEchartsChaoWa" style="width: 20%;height: 120%;margin-right: 50px;margin-top: 18px"></div>
    </div>
    <!--    工序用时-->
    <div style="display: flex;height: 10px;justify-content: space-between">
      <div style="display: flex;justify-content: space-between;width: 20%;height: 30px;line-height: 30px;font-size: 15px;padding-left: 10px;margin-left: 50px;margin-top: 45px;color: #fff;background: linear-gradient(to right, rgb(27, 77, 92), rgb(8, 20, 32));border-left: 5px solid rgb(85, 183, 199)">
        <span>工序用时 </span>
        <span style="margin-left: -20%"></span>
        <img src="
https://czsc.oss-cn-beijing.aliyuncs.com/qdimgs/a_right.png" style="margin-right: 10px" @click="">
      </div>
    </div>
    <div>
      <div id="myEchartsSdgx" style="width: 96%;height: 32vh;margin-left: 45px;margin-top: 44px"></div>
    </div>
    <!--    中间滚动数据-->
    <div style="position: absolute;left: 25%;top: 37%;width: 50%;height: 28%;overflow: hidden">
      <div style="width: 100%;">
        <div id="alldiv2">
          <div style="width: 35%;height: 30px;background-color: #153947">
            工点
          </div>
          <div style="width: 15%;background-color: #153947">
            当日进尺
          </div>
          <div style="width: 35%;background-color: #153947">
            计划进尺
          </div>
          <div style="width: 38%;background-color: #153947">
            是否完成
          </div>
        </div>
      </div>

      <div style="height: 40%;">
        <vue3-seamless-scroll :list="list" step="0.2" hover="true" wheel="true" class="scroll">
          <div class="item" v-for="(item, index) in list" :key="index" style="font-size:0;padding-top:1px">
            <div id="alldiv" v-if="index % 2 === 0" style="background-color: #081420;margin-top:-5px">
              <div style="width: 35%;height: 40px">
                {{ item.opername }}
              </div>
              <div style="width: 15%">
                {{ item.dayend }}
              </div>
              <div style="width: 35%;">
                {{ item.dayplan }}
              </div>
              <div style="width: 35%;">
                {{ item.flag }}
              </div>
            </div>
            <div id="alldiv" v-if="index % 2 !== 0" style="background-color: #153947;margin-top:-5px">
              <div style="width: 35%;height: 40px;">
                {{ item.opername }}
              </div>
              <div style="width: 15%">
                {{ item.dayend }}
              </div>
              <div style="width: 35%;">
                {{ item.dayplan }}
              </div>
              <div style="width: 35%;">
                {{ item.flag }}
              </div>
            </div>
          </div>
        </vue3-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import {setNowDate, setNowTimes, setNowTimesInHole} from "@/utils/time";
import {Vue3SeamlessScroll} from "vue3-seamless-scroll";
import $ from "jquery"

export default {
  name: "show_progess_befor",
  inject: ['reload'],
  data() {
    return {
      bjsrc: 'https://czsc.oss-cn-beijing.aliyuncs.com/bj/show_progess_bj.png',
      allNum: '',
      list: '',
      time1: '',
      time: '',
      timer: null,
      timenow: '',
      stop: true,
      sumMile: '',
      daydennum: '',
      dayendurl: '',
      sumAllZ: '',
      czurl: '',
      chaowaurl: '',
      urlqz: ''
    }
  },
  components: {
    Vue3SeamlessScroll
  },
  setup() {
    let list = JSON.parse(sessionStorage.getItem('1'));
    return {list}
  },
  created() {
    // console.log(sessionStorage.getItem('time'))
    if (sessionStorage.getItem('time') === null) {
      this.time = setNowDate(new Date());
    } else {
      this.time = sessionStorage.getItem('time');
    }
    // let now_time = new Date(this.time).getTime();
    // let gd_time = new Date('2022-08-31').getTime();

    // if (now_time < gd_time){
    //   this.urlqz = 'https://www.gjegm.ltd/czsczq'
    //   //this.urlqz = 'http://localhost:8082'
    // }else {
    //   this.urlqz = 'https://www.gjegm.ltd/showdata'
    //
    // }

    //this.urlqz = 'https://www.gjegm.ltd/czsczq'
		//this.time = setNowDate(new Date())
    this.dayendurl = 'https://www.cr12cz.cn/show_sdgxnum_alone?time=' + this.time;
    this.czurl = 'https://www.cr12cz.cn/show_hunnum_alone?time=' + this.time;
    this.chaowaurl = 'https://www.cr12cz.cn/show_chaowa_alone?time=' + this.time;
    this.reAll();
  },
  methods: {
    reAll() {
      setTimeout(this.getRateOfProgessZ, 100);
      setTimeout(this.getRateOfProgessF, 100);
      setTimeout(this.getRateOfProgessHunNum, 100);
      setTimeout(this.getRateOfProgessChaoWa, 100);
      setTimeout(this.getRateOfProgessSdgx, 100);
      setTimeout(this.getRateOfProgessOperDayNum, 100);
      setTimeout(this.getRateOfProgessOperDayNuma, 100);
      setTimeout(this.getDateTable, 100);
    },

    getRateOfProgessZ() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsZ'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('/ShowProgess/getRateOfProgessAll', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            this.sumMile = this.sumMile * 1 + response.obj2[i] * 1;
          }
          this.sumMile = this.sumMile.toFixed(2);
          // 防止返回图表不重新加载
          document.getElementById('myEchartsZ').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位: 米',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 13
                },
                top: 25,
                left: 5,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '75%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                color: '#fff'
              },

            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                name: '正洞开挖',
                data: y,
                type: 'bar',
                barWidth: 30,
                barGap: '30%',
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: 'rgb(241,189,5)',
                        fontSize: 10,
                        fontWeight: '50000'
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
        bdst: '1',
        tunnel: '0'
      })
    },
    getRateOfProgessF() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsF'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('/ShowProgess/getRateOfProgessAll', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj2.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            this.sumAllZ = this.sumAllZ * 1 + response.obj2[i] * 1;
          }
          this.sumAllZ = this.sumAllZ.toFixed(2);
          // 防止返回图表不重新加载
          document.getElementById('myEchartsF').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位: 米',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 13
                },
                top: 25,
                left: 15,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '70%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                color: '#fff'
              }
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                name: '辅助坑道开挖',
                data: y,
                type: 'bar',
                barWidth: 30,
                barGap: '30%',
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: '#f1bd05',
                        fontSize: 12,
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
        bdst: '1',
        tunnel: '1'
      })
    },
    getRateOfProgessHunNum() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsHunNum'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('/ShowProgess/getCzbetonHunNum', (response) => {
        console.log(response)
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          let sumMile = 0;
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            sumMile = sumMile + response.obj2[i] * 1;
          }
          // 防止返回图表不重新加载
          document.getElementById('myEchartsHunNum').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位: m³',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 13
                },
                top: 10,
                left: 10,
              }
            ],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '2%',
              left: '70%'
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                color: '#fff'
              }
            },
            yAxis: [
              {
                show: true,
                type: 'value',
                splitLine: {
                  show: false
                },
                axisLine: {
                  show: false, //隐藏y轴
                }
              },
              {
                type: 'value',
                axisLabel: {
                  show: false, //隐藏刻度值
                }
              }
            ],
            series: [{
              name: '实际喷射',
              type: 'line',
              barWidth: 30,
              tooltip: {
                valueFormatter: function (value) {
                  return '实际喷射 ' + value + ' m³';
                }
              },
              color: '#f1bd05',
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: {
                      //数值样式
                      color: '#f1bd05',
                      fontSize: 12,
                    },
                  },
                }
              },
              data: response.obj2[0]
            },
              {
                name: '设计喷射',
                tooltip: {
                  valueFormatter: function (value) {
                    return '设计喷射 ' + value + ' m³';
                  }
                },
                type: 'bar',
                barWidth: 30,
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: '#ffffff',
                        fontSize: 12,
                      },
                    },
                  }
                },
                data: response.obj2[1]
              }
            ]
          });
        }
      }, {
        time: this.time
      })
    },
    getRateOfProgessChaoWa() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsChaoWa'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('/ShowProgess/getCzbetonChaoWa', (response) => {
        console.log(response)
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          let sumMile = 0;
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            sumMile = sumMile + response.obj2[i] * 1;
          }
          // 防止返回图表不重新加载
          document.getElementById('myEchartsChaoWa').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            color: ['rgb(250,200,88)', 'rgb(238,102,102)'],
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              },
            },
            title: [
              {
                text: '单位: CM',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 13
                },
                top: 10,
                left: 10,
              }
            ],
            legend: {
              textStyle: {
                color: '#ffffff'
              },
              padding: 0,
              top: '10%',
              left: '70%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                color: '#fff'
              }
            },
            yAxis: [
              {
                show: true,
                type: 'value',
                splitLine: {
                  show: false
                },
                axisLine: {
                  show: false, //隐藏y轴
                }
              },
              {
                type: 'value',
                axisLabel: {
                  show: false, //隐藏刻度值
                }
              }
            ],
            series: [{
              name: '最大线性超挖',
              type: 'line',
              tooltip: {
                valueFormatter: function (value) {
                  return '最大线性超挖 ' + value;
                }
              },
              itemStyle: {
                normal: {
                  label: {
                    show: true, //开启显示
                    position: 'top', //在上方显示
                    textStyle: {
                      //数值样式
                      color: '#f1bd05',
                      fontSize: 12,
                    },
                  },
                }
              },
              label: {
                show: true,

              },
              data: response.obj2[0]
            },
              {
                name: '平均线性超挖',
                type: 'bar',
                barWidth: 30,
                tooltip: {
                  valueFormatter: function (value) {
                    return '平均线性超挖 ' + value;
                  }
                },
                color: {
                  type: 'bar',
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [{
                    offset: 0, color: 'rgb(80, 180, 196)' // 0% 处的颜色
                  }, {
                    offset: 0.3, color: 'rgb(80, 180, 196)' // 100% 处的颜色
                  },
                    {
                      offset: 1, color: 'rgb(8, 20, 32)' // 100% 处的颜色
                    }],
                  global: false // 缺省为 false
                },
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: '#ffffff',
                        fontSize: 12,
                      },
                    },
                  }
                },
                data: response.obj2[1]
              }
            ]
          });
        }
      }, {
        time: this.time
      })
    },
    getRateOfProgessSdgx() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsSdgx'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('/ShowProgess/getSdgxDate', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          let sumMile = 0;
          for (let i = 0; i < response.obj.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            sumMile = sumMile + response.obj2[i] * 1;
          }
          // 防止返回图表不重新加载
          document.getElementById('myEchartsSdgx').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                color: '#fff',
                rotate: 20
              }
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: false
              }
            },
            series: [
              {
                data: y,
                type: 'line',
                barWidth: 30,
                barGap: '60%',
                color: '#f1bd05',
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: '#f1bd05',
                        fontSize: 12,
                      },
                      formatter: function (data) {
                        return Math.trunc(data.value / 60) + '时' + data.value % 60 + '分';
                      }
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
        time: this.time
      })
    },
    getRateOfProgessOperDayNum() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsOperDayNum'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('/ShowProgess/getOperDayNum', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          for (let i = 0; i < response.obj2.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            this.daydennum = this.daydennum * 1 + response.obj2[i] * 1;
          }
          this.daydennum = this.daydennum.toFixed(2);
          // 防止返回图表不重新加载
          document.getElementById('myEchartsOperDayNum').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位: 米',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 13
                },
                top: 26,
                left: 15,
              }
            ],
            legend: {
              textStyle: {
                color: '#f1bd05'
              },
              padding: 0,
              top: '10%',
              left: '75%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                color: '#fff'
              }
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: true
              }
            },
            series: [
              {
                name: '趋势',
                data: y,
                type: 'line',
                barWidth: 30,
                barGap: '30%',
                color: '#f1bd05',
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: '#f1bd05',
                        fontSize: 12,
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
        time: this.time
      })
    },
    getRateOfProgessOperDayNuma() {
      let x = [];
      let y = [];
      // 基于准备好的dom，初始化echarts实例
      const myChart = this.$echarts.init(document.getElementById('myEchartsOperDayNuma'));
      //数据加载完之前先显示一段简单的loading动画
      myChart.showLoading();
      this.axios.post('/ShowProgess/getOperDayNum', (response) => {
        if (response.obj.length !== 0) {
          myChart.hideLoading();    //隐藏加载动画
          let sumMile = 0;
          for (let i = 0; i < response.obj2.length; i++) {
            x.push(response.obj[i]);
            y.push(response.obj2[i]);
            sumMile = sumMile * 1 + response.obj2[i] * 1;
          }

          // 防止返回图表不重新加载
          document.getElementById('myEchartsOperDayNuma').setAttribute('_echarts_instance_', '');
          // 绘制图表
          myChart.setOption({
            title: [
              {
                text: '单位',
                textStyle: {
                  color: '#fff',
                  fontWeight: 'normal',
                  fontSize: 13
                },
                top: 25,
                left: 25,
              }
            ],
            legend: {
              textStyle: {
                color: '#f1bd05'
              },
              padding: 0,
              top: '10%',
              left: '85%'
            },
            xAxis: {
              type: 'category',
              data: x,
              axisLabel: {
                interval: 0,
                fontSize: 10,
                color: '#fff'
              }
            },
            yAxis: {
              type: 'value',
              axisLine: {
                show: true //y轴线消失
              },
              splitLine: {
                show: true
              }
            },
            series: [
              {
                name: '趋势',
                data: y,
                type: 'line',
                barWidth: 30,
                barGap: '30%',
                color: '#f1bd05',
                itemStyle: {
                  normal: {
                    label: {
                      show: true, //开启显示
                      position: 'top', //在上方显示
                      textStyle: {
                        //数值样式
                        color: '#f1bd05',
                        fontSize: 12,
                      },
                    },
                  }
                },
              }
            ]
          });
        }
      }, {
        time: this.time
      })
    },
    getDateTable() {
      this.axios.post('/ShowProgess/getPlanAndReal', (response) => {
        sessionStorage.removeItem('1');
        this.list = response.obj;
        sessionStorage.setItem('1', JSON.stringify(this.list));
      }, {
        time: this.time
      });
    },
    updateTimeRe() {
      this.dayendurl = 'https://www.cr12cz.cn/#/show_sdgxnum_alone?time=' + this.time;
      this.czurl = 'https://www.cr12cz.cn/#/show_hunnum_alone?time=' + this.time;
      this.chaowaurl = 'https://www.cr12cz.cn/#/show_chaowa_alone?time=' + this.time;
      sessionStorage.setItem('time', this.time);
      this.reload();
    }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.timenow = setNowTimesInHole(new Date())
    }, 1000)

  }

}
</script>

<style scoped>
.bj {
  width: 100%;
  height: 100%;
  top: 0; /*这里是设置与顶部的距离*/
  left: 0; /*这里是设置与左边的距离*/
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  overflow: hidden;
}

.scroll {
  height: 1024px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto 0px auto;
}

.scroll .item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 3px 0;
}

#alldiv {
  width: 100%;
  display: flex;
  color: #fff;
  text-align: center;
  line-height: 40px;
  font-size: 15px;
  margin: 0;
  padding: 0;
}

#alldiv2 {
  width: 100%;
  display: flex;
  color: #fff;
  text-align: center;
  line-height: 30px;
  font-size: 15px;
}

::v-deep .el-input__inner {
  /*background-color: transparent !important;*/
  /*box-shadow: 1px 1px 5px 1px RGB(48, 84, 95) inset;*/
  background-color: rgb(12, 37, 48);
  height: 50px;
  line-height: 50px;
  width: 150px;
  font-size: 15px;
  border: 2px solid RGB(48, 84, 95);
  color: #fff;
  text-align: center;
  border-radius: 10px;
}

::v-deep .el-date-editor .el-input__icon:nth-child(1){
  display: none;
}

#timestyle {
  color: #fff;
  margin-right: 30px;
  margin-top: 25px;
  height: 50px;
  line-height: 50px;
  padding: 0px 20px 0px 20px;
  background-color: rgb(12, 37, 48);
  font-size: 15px;
  border: 2px solid RGB(48, 84, 95);
  border-radius: 10px;
}


</style>